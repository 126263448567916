import React, {useState} from "react";
import ContactFormModel from "./contact-form-model";

export default function CTA(props) {
    const [openModel, setOpenModel] = useState(false);

    return (
      <div className="lead-cta-section">
          <ContactFormModel show={openModel} onHide={() => setOpenModel(false)} />
          <p>{props.text}</p>
          <button className="btn btn-primary" onClick={(e) => setOpenModel(true)}>Talk To An Expert</button>
      </div>
    );
}